import React from "react"
import { graphql } from "gatsby"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import Seo from "../../components/seo"
import Layout from "../../app/layout"
import { Row, Col, Breadcrumb } from "antd"
import Container from "../../components/Container"
import Masthead from "../../components/Masthead"
import { ChevronRight } from "../../components/Icon"
import ScrollAnimation from "react-animate-on-scroll"

const VisionPage = ({ data: { aboutVisionMissions: data = {} } }) => {
  const { t } = useI18next()
  return (
    <Layout module_name="about" page_name="vision-mission">
      <Seo
        title={data.header_banner_title}
        module_name="about"
        page_name="vision-mission"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.header_banner_title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={data.header_banner_image}
        title={data.header_banner_title}
        textBottom={t("Lihat Selengkapnya")}
      />

      <div className="section section-secondary section-big-spaceBottom text-medium">
        <Container>
          <Row
            gutter={40}
            justify="space-between"
            style={{ margin: "0 -15px" }}
          >
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2 className="section-title-huge">{data.vision_title}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: data.vision_content }}
                />
                <p></p>
              </ScrollAnimation>
            </Col>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}
              >
                <h2 className="section-title-huge">{data.mission_title}</h2>
                <p dangerouslySetInnerHTML={{ __html: data.mission_content }} />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section text-medium">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <img
              src={data.middle_banner_image}
              alt="fokus kami"
              width="100%"
              className="image-offsetTop"
            />
          </ScrollAnimation>
          <Row gutter={40} align="middle" style={{ margin: "0 -15px" }}>
            <Col span={24} md={12} style={{ padding: "0 15px" }}></Col>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2 className="section-title-huge">{data.our_focus_title}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: data.our_focus_content }}
                />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default VisionPage

export const query = graphql`
  query visionPageQuery($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    aboutVisionMissions(lang: { eq: $language }) {
      header_banner_image
      header_banner_title
      header_slug
      middle_banner_image
      mission_content
      mission_title
      our_focus_content
      our_focus_title
      updated_at
      vision_content
      vision_title
    }
  }
`
